import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

// ---- action / redux
import { actModalChange, actModalIsOk, actResetModal } from '../../../../actions/modal';
import { showLoading, hideLoading } from '../../../../actions/ui';
import { datePickup } from '../../../../redux/actions/index';
import * as userApi from '../../../../api/user';
import * as userShareApi from '../../../../api/other/useShare';
import * as branchApi from '../../../../api/branchinfor';
import * as surveyApi from '../../../../api/other/survey';

import * as memberShip from '../../../../api/azumayavietnam/menbership';

// api / support
import ManagerUserGlist from '../../../../components/Postion/Admin/ManagerUserGlist';
import ManagerBranch from '../../../../components/Postion/Admin/ManagerBranch';
import ModalSupportOption from '../../../../components/SupportComponents/Modal/ModalOption';

import './membership.css'
import ManagerAllUser from '../../../../components/Postion/Admin/ManagerUser';
import ManagerSurvey from '../../../../components/Postion/Admin/ManagerSurvey';
import MemberShipList from '../../../../components/Postion/Admin/MemberShip/MemberShipList';

function MemberShip() {

    const dispatch = useDispatch();
    const token = useSelector(state => state.token);
    const allBranchInform = useSelector(state => state.allBranchInformation);
    const dataBranchOnState = useSelector(state => state.datepickup.selectAdminUser)
    var [loading, setLoading] = useState(false);

    var [data, setData] = useState([]);
    var [dataUser, setDataUser] = useState([]);
    var [dataUserDatabase, setDataUserDatabase] = useState([]);
    var [selectMenu, setSelectMenu] = useState("member");

    useEffect(() => {

        const takeListUser = async () => {
            const result = await memberShip.takeListMemberShip(0, 1000,token);
            console.log("result", result)
            if(result) setDataUser(result.data.data);
        }

        if(token) takeListUser(token);
        
    },[token])

    return (
            <React.Fragment>
            <h1>Member Ship Manager</h1>
            <div className={loading ? "" : "op-hidden"}>
                <p>loading...</p>
                <img src="https://res.cloudinary.com/dsm20cmen/image/upload/v1626411475/store/loading_short_daigrr.gif" alt="loading" />
            </div>

            <div className="d-flex manager-user">
                <div className="manager-user_menu">
                    <ul className="manager-user_menu-list" style={{ listStyle: 'none' }}>
                        <li className={"manager-user_menu-item " + (selectMenu === "member" ? "select" : "")}
                            onClick={() => setSelectMenu("member")}>Member</li>
                        <li className={"manager-user_menu-item " + (selectMenu === "branchInfor" ? "select" : "")}
                            onClick={() => setSelectMenu("branchInfor")}>Voucher</li>
                        {/* <li className={"manager-user_menu-item " + (selectMenu === "user" ? "select" : "")}
                            onClick={() => setSelectMenu("user")}>User</li>
                        <li className={"manager-user_menu-item " + (selectMenu === "glist" ? "select" : "")}
                            onClick={() => setSelectMenu("glist")}>Glist</li>
                        <li className={"manager-user_menu-item " + (selectMenu === "survey" ? "select" : "")}
                            onClick={() => setSelectMenu("survey")}>Survey</li> */}
                    </ul>
                </div>


            <div style={{ width: '85%', marginLeft: "24px" }} className={"manager-user-content " + (selectMenu === "member" ? "" : "op-hidden")}>
                    <MemberShipList
                        data={dataUser}
                        target = "login"
                        // listArea={listArea}
                        // listBranch={listBranch}
                        // selectArea={dataBranchOnState.user.area}
                        // selectBranch={dataBranchOnState.user.branch}
                        // changeSelectArea={changeSelectAreaGlist}
                        // changeSelectBranchID={changeSelectBranchIDGlist}
                        // onShowActionUserManager={onShowActionUserManager}
                        // loadData={loadDataUser}
                        // updateNewPassToServer={updateNewPassToServer}

                        // onChangeActionGlist={onShowChangeAction}
                        // onChangeNoteGlist={onChangeNoteGlist}
                        // onUpdateNoteToServer={onUpdateNoteToServer}

                    />
                </div>
            </div>

        </React.Fragment >
    );
}

export default MemberShip;