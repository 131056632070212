import React from 'react';
import TableShowMemberShipUser from './TableShowMemberShipUser';

function MemberShipList(props) {
    const { data } = props;
    return (
        <>
            <div>
                MemberShipList
            </div>
            <div className='rp manager-user_branch'>
                <TableShowMemberShipUser 
                    data= {data}
                />
            </div>

        </>

    )
}

export default MemberShipList;