import React from "react";
import "./Table.css"; // Optional, for custom styles

function BookingTable(props) {
  const {byArea, byBranch  } = props.data;

  console.log("byBranch.AZDN", byBranch.AZDN)
  return (
    <div className="table-container">
      <table border="1">
        <thead>
          <tr>
            <th rowSpan="2" style={{width:'50px'}}>Area</th>
            <th rowSpan="2" style={{width:'120px'}}>Branch</th>
            <th rowSpan="2" style={{width:'20px'}}>Booking</th>
            <th rowSpan="2" style={{width:'50px'}}>Room Amount</th>
            <th colSpan="4">Language</th>
            <th colSpan="4">Device Type</th>
          </tr>
          {/* <tr style={{border:'1px solid'}}>
            <th style={{width:'70px'}} className="btn-ja"></th>
            <th style={{width:'70px'}} className="btn-en"></th>
            <th style={{width:'70px'}} className="btn-vie"></th>
            <th style={{width:'70px'}} className="btn-kor"></th>
          </tr> */}
          <tr>
            <th style={{width:'20px', textAlign:'center', alignItems: 'center'}}>
              <div className="btn-ja"></div>
            </th>
            <th style={{width:'20px', textAlign:'center', alignItems: 'center'}}>
              <div className="btn-en"></div>
            </th>
            <th style={{width:'20px'}} >
              <div className="btn-vie"></div>
            </th>
            <th style={{width:'20px', textAlign:'center', alignItems: 'center'}}>
              <div className="btn-kor"></div>
            </th>
            <th style={{width:'70px'}}>PC</th>
            <th style={{width:'70px'}}>Phone</th>
            <th style={{width:'70px'}}>Tablet</th>
            <th style={{width:'70px'}}>App</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan="5">Ha Noi</td>
            <td>Hai Ba Trung 1</td>
            <td>{byBranch.AZHBT1.booking}</td>
            <td>{byBranch.AZHBT1.roomAmount}</td>
            <td>{byBranch.AZHBT1.language.ja}</td>
            <td>{byBranch.AZHBT1.language.en}</td>
            <td>{byBranch.AZHBT1.language.vi}</td>
            <td>{byBranch.AZHBT1.language.ko}</td>

            <td>{byBranch.AZHBT1.deviceType.pc}</td>
            <td>{byBranch.AZHBT1.deviceType.phone}</td>
            <td>{byBranch.AZHBT1.deviceType.tablet}</td>
            <td>{byBranch.AZHBT1.deviceType.app}</td>
          </tr>
          <tr>
            <td>Kim Ma 2</td>
            <td>{byBranch.AZKM2.booking}</td>
            <td>{byBranch.AZKM2.roomAmount}</td>
            <td>{byBranch.AZKM2.language.ja}</td>
            <td>{byBranch.AZKM2.language.en}</td>
            <td>{byBranch.AZKM2.language.vi}</td>
            <td>{byBranch.AZKM2.language.ko}</td>
            <td>{byBranch.AZKM2.deviceType.pc}</td>
            <td>{byBranch.AZKM2.deviceType.phone}</td>
            <td>{byBranch.AZKM2.deviceType.tablet}</td>
            <td>{byBranch.AZKM2.deviceType.app}</td>
          </tr>
          <tr>
            <td>Kim Ma 3</td>
            <td>{byBranch.AZKM3.booking}</td>
            <td>{byBranch.AZKM3.roomAmount}</td>
            <td>{byBranch.AZKM3.language.ja}</td>
            <td>{byBranch.AZKM3.language.en}</td>
            <td>{byBranch.AZKM3.language.vi}</td>
            <td>{byBranch.AZKM3.language.ko}</td>
            <td>{byBranch.AZKM3.deviceType.pc}</td>
            <td>{byBranch.AZKM3.deviceType.phone}</td>
            <td>{byBranch.AZKM3.deviceType.tablet}</td>
            <td>{byBranch.AZKM3.deviceType.app}</td>
          </tr>
          <tr>
            <td>Linh Lang</td>
            <td>{byBranch.AZLL.booking}</td>
            <td>{byBranch.AZLL.roomAmount}</td>
            <td>{byBranch.AZLL.language.ja}</td>
            <td>{byBranch.AZLL.language.en}</td>
            <td>{byBranch.AZLL.language.vi}</td>
            <td>{byBranch.AZLL.language.ko}</td>
            <td>{byBranch.AZLL.deviceType.pc}</td>
            <td>{byBranch.AZLL.deviceType.phone}</td>
            <td>{byBranch.AZLL.deviceType.tablet}</td>
            <td>{byBranch.AZLL.deviceType.app}</td>
          </tr>
          <tr >
            <td className="bg-green_yellow">Total</td>
            <td className="bg-green_yellow">{byArea["Ha Noi"].booking}</td>
            <td className="bg-green_yellow">{byArea["Ha Noi"].roomAmount}</td>
            <td className="bg-green_yellow"></td>
            <td className="bg-green_yellow"></td>
            <td className="bg-green_yellow"></td>
            <td className="bg-green_yellow"></td>
            <td className="bg-green_yellow"></td>
            <td className="bg-green_yellow"></td>
            <td className="bg-green_yellow"></td>
            <td className="bg-green_yellow"></td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td rowSpan="4">HCM</td>
            <td>Thai Van Lung 1</td>
            <td>{byBranch.AZTVL1.booking}</td>
            <td>{byBranch.AZTVL1.roomAmount}</td>
            <td>{byBranch.AZTVL1.language.ja}</td>
            <td>{byBranch.AZTVL1.language.en}</td>
            <td>{byBranch.AZTVL1.language.vi}</td>
            <td>{byBranch.AZTVL1.language.ko}</td>
            <td>{byBranch.AZTVL1.deviceType.pc}</td>
            <td>{byBranch.AZTVL1.deviceType.phone}</td>
            <td>{byBranch.AZTVL1.deviceType.tablet}</td>
            <td>{byBranch.AZTVL1.deviceType.app}</td>
          </tr>
          <tr>
            <td>Thai Van Lung 2</td>
            <td>{byBranch.AZTVL2.booking}</td>
            <td>{byBranch.AZTVL2.roomAmount}</td>
            <td>{byBranch.AZTVL2.language.ja}</td>
            <td>{byBranch.AZTVL2.language.en}</td>
            <td>{byBranch.AZTVL2.language.vi}</td>
            <td>{byBranch.AZTVL2.language.ko}</td>
            <td>{byBranch.AZTVL2.deviceType.pc}</td>
            <td>{byBranch.AZTVL2.deviceType.phone}</td>
            <td>{byBranch.AZTVL2.deviceType.tablet}</td>
            <td>{byBranch.AZTVL2.deviceType.app}</td>
          </tr>
          <tr>
            <td>Le Thanh Tong</td>
            <td>{byBranch.AZLTT.booking}</td>
            <td>{byBranch.AZLTT.roomAmount}</td>
            <td>{byBranch.AZLTT.language.ja}</td>
            <td>{byBranch.AZLTT.language.en}</td>
            <td>{byBranch.AZLTT.language.vi}</td>
            <td>{byBranch.AZLTT.language.ko}</td>
            <td>{byBranch.AZLTT.deviceType.pc}</td>
            <td>{byBranch.AZLTT.deviceType.phone}</td>
            <td>{byBranch.AZLTT.deviceType.tablet}</td>
            <td>{byBranch.AZLTT.deviceType.app}</td>
          </tr>
          <tr>
            <td className="bg-green_yellow">Total</td>
            <td className="bg-green_yellow">{byArea["Ho Chi Minh"].booking}</td>
            <td className="bg-green_yellow">{byArea["Ho Chi Minh"].roomAmount}</td>
            <td className="bg-green_yellow"></td>
            <td className="bg-green_yellow"></td>
            <td className="bg-green_yellow"></td>
            <td className="bg-green_yellow"></td>
            <td className="bg-green_yellow"></td>
            <td className="bg-green_yellow"></td>
            <td className="bg-green_yellow"></td>
            <td className="bg-green_yellow"></td>
          </tr>
        </tbody>

{/* { console.log("byBranch.AZDN.booking", byBranch.AZDN)} */}
        <tbody>
          <tr>
            <td rowSpan="4">Da Nang</td>
            <td>Da Nang</td>
            <td>{byBranch.AZDN ? byBranch.AZDN.booking : 0}</td>
            <td>{byBranch.AZDN ? byBranch.AZDN.roomAmount : 0 }</td>
            <td>{byBranch.AZDN ? byBranch.AZDN.language.ja : 0 }</td>
            <td>{byBranch.AZDN ? byBranch.AZDN.language.en : 0 }</td>
            <td>{byBranch.AZDN ? byBranch.AZDN.language.vi : 0 }</td>
            <td>{byBranch.AZDN ? byBranch.AZDN.language.ko : 0 }</td>
            <td>{byBranch.AZDN ? byBranch.AZDN.deviceType.pc : 0 }</td>
            <td>{byBranch.AZDN ? byBranch.AZDN.deviceType.phone : 0 }</td>
            <td>{byBranch.AZDN ? byBranch.AZDN.deviceType.tablet : 0 }</td>
            <td>{byBranch.AZDN ? byBranch.AZDN.deviceType.app : 0 }</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td rowSpan="4">Hai Phong</td>
            <td>Hai Phong</td>
            <td>{byBranch.AZHP.booking}</td>
            <td>{byBranch.AZHP.roomAmount}</td>
            <td>{byBranch.AZHP.language.ja}</td>
            <td>{byBranch.AZHP.language.en}</td>
            <td>{byBranch.AZHP.language.vi}</td>
            <td>{byBranch.AZHP.language.ko}</td>
            <td>{byBranch.AZHP.deviceType.pc}</td>
            <td>{byBranch.AZHP.deviceType.phone}</td>
            <td>{byBranch.AZHP.deviceType.tablet}</td>
            <td>{byBranch.AZHP.deviceType.app}</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td rowSpan="4">Cambodia</td>
            <td>Phnom Penh</td>
            <td>{byBranch.AZPP.booking}</td>
            <td>{byBranch.AZPP.roomAmount}</td>
            <td>{byBranch.AZPP.language.ja}</td>
            <td>{byBranch.AZPP.language.en}</td>
            <td>{byBranch.AZPP.language.vi}</td>
            <td>{byBranch.AZPP.language.ko}</td>
            <td>{byBranch.AZPP.deviceType.pc}</td>
            <td>{byBranch.AZPP.deviceType.phone}</td>
            <td>{byBranch.AZPP.deviceType.tablet}</td>
            <td>{byBranch.AZPP.deviceType.app}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BookingTable;
