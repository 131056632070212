/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

// ---- action / redux
import { actModalChange, actModalIsOk, actResetModal } from '../../../../actions/modal';
import { showLoading, hideLoading } from '../../../../actions/ui';
import { datePickup } from '../../../../redux/actions/index';
import * as userApi from '../../../../api/user';
import * as userShareApi from '../../../../api/other/useShare';
import * as branchApi from '../../../../api/branchinfor';
import * as surveyApi from '../../../../api/other/survey';
// api / support
import ManagerUserGlist from '../../../../components/Postion/Admin/ManagerUserGlist';
import ManagerBranch from '../../../../components/Postion/Admin/ManagerBranch';
import ModalSupportOption from '../../../../components/SupportComponents/Modal/ModalOption';

import './managerUser.css'
import ManagerAllUser from '../../../../components/Postion/Admin/ManagerUser';
import ManagerSurvey from '../../../../components/Postion/Admin/ManagerSurvey';

ManagerUser.propTypes = {
};

function ManagerUser() {
    const dispatch = useDispatch();
    const token = useSelector(state => state.token);
    const allBranchInform = useSelector(state => state.allBranchInformation);
    const dataBranchOnState = useSelector(state => state.datepickup.selectAdminUser)
    var [loading, setLoading] = useState(false);

    var [data, setData] = useState([]);
    var [dataBranchInfor, setDataBranchInfor] = useState({});
    var [dataUser, setDataUser] = useState([]);
    var [dataUserDatabase, setDataUserDatabase] = useState([]);
    var [dataSurveyConfig, setDataSurveyConfig] = useState({});
    var [selectMenu, setSelectMenu] = useState("userDatabase");

    const listArea = allBranchInform === undefined ? [] : Object.keys(allBranchInform);
    var listBranch = renderListBranch(dataBranchOnState[selectMenu].area);

    function renderListBranch(areaValue) {
        let list = [];
        if (JSON.stringify(allBranchInform) !== '{}' && areaValue !== "Select Area") {
            allBranchInform[areaValue].forEach(branch => {
                list.push(branch.name);
            })
        } else {
            list = []
        }
        return list;
    }

    function onShowActionUserManager(item) {
        var changeModal;
    }

    function updateNewPassToServer(data) {
        const updateToServer = async () => {
            dispatch(showLoading())
            const response = await userApi.changeNewPasswordByAdmin(data, token);

            if (response.data) {
                saveToast(response.data.msg);
            }
            dispatch(hideLoading());
        };
        updateToServer();
    }

    function onShowChangeAction(item, type, target) {
        var changeModal;
        console.log("target", target);
        console.log("item", item);

        changeModal = actModalChange("centered",
            "Confirm change " + type.toUpperCase(), <ModalSupportOption
            title={`Change this id to ${type.toUpperCase()} LIST?  `}
            button1="Close"
            button2={type === "block" ? "Block" : "Activate"}
            featureButton1={() => closeModal()}
            typeButton2={type === "block" ? "btn-danger" : "btn-primary"}
            featureButton2={() => onChangeActionGlist(item, type, target)} // target = glist / login
            type={2}
            itemOption={item}
        />)

        dispatch(changeModal);
        const openModal = actModalIsOk();
        dispatch(openModal);
    }

    function onChangeNoteGlist(item, value, type) {
        const newData = { ...data };
        //  var listCheck = type === "block" ? newData.listBlock : newData.listActivated
        if (type === "block") {
            const findIndex = newData.listBlock.findIndex(({ id }) => id === item.id);
            newData.listBlock[findIndex] = item;
        } else {
            const findIndex = newData.listActivated.findIndex(({ id }) => id === item.id);
            newData.listActivated[findIndex] = item;
        }
        setData(newData);
        // update to server
    }

    function onUpdateNoteToServer(item, type, target) {

        console.log(" vo here onUpdateNoteToServer target:", target)
        console.log(" vo here onUpdateNoteToServer item:", item)

            const updateToServer = async () => {
            setLoading(true);
            if (target === "glist-note") {
                await branchApi.updateBranchOther(dataBranchOnState.glist.ID, {
                    data: item, type, target
                }, token);
            } else if (target === "loginDatabase-note") {
                if(item) {
                    const result = await userShareApi.updateUserShareOther({
                        data: item, type, target, email: item.email
                    }, token); //dataBranchOnState.glist.ID, 
    
                    console.log("result", result)
                }
            } else {  // login-note
                await userApi.updateUserOther({
                    data: item, type, email: item.email, target
                }, token);
            }
            setLoading(false);
        }
        updateToServer();
    }

    function onChangeActionGlist(item, type, target) {
        const changeGlist = async () => {
            setLoading(true);
            if (target === "glist") { // glist
                const response = await branchApi.updateBranchOther(dataBranchOnState.glist.ID, {
                    data: item, type, target
                }, token);

                if (response.data.status === 1) {
                    setData(response.data.data.glist);
                    saveToast(response.data.msg);
                }
            } else if (target === "login") { 
                const response = await userApi.updateUserOther({
                    data: item, email: item.email, type, target
                }, token);

                if (response.data.status === 1) {
                    //     setData(response.data.data.login);
                    const newListUser = [...dataUser];
                    const findIndex = newListUser.findIndex(({ email }) => email === item.email);
                    newListUser[findIndex].other = response.data.data;
                    setDataUser(newListUser)
                    saveToast(response.data.msg);
                }
            } else { // target === "loginDatabase"
                const response = await userShareApi.updateUserShareOther({
                    data: item, email: item.email, type, target
                }, token);
                
                if (response.data.status === 1) {
                    //     setData(response.data.data.login);
                    const newListUser = [...dataUserDatabase];
                    const findIndex = newListUser.findIndex(({ email }) => email === item.email);
                    newListUser[findIndex].other = response.data.data;
                    setDataUserDatabase(newListUser)
                    saveToast(response.data.msg);
                }
            }
            setLoading(false);
            closeModal();
        }
        changeGlist();
    }

    function changeSelectAreaGlist(value) {
        let dataSelect = {
            ID: 0,
            area: value,
            branch: "Select Branch",
        }
        dispatch(datePickup.changeSelectAdminUser({ dataSelect, type: selectMenu }));//reset select BranchID
    }

    function changeSelectBranchIDGlist(value) {
        // change BranchID
        const areaFindIndex = dataBranchOnState[selectMenu].area === "Select Area" ? 0 : dataBranchOnState[selectMenu].area;
        const findindex = allBranchInform[areaFindIndex].findIndex(({ name }) => name === value);
        let branchIdSelect = allBranchInform[areaFindIndex][findindex].branchID;
        let dataSelect = {
            ...dataBranchOnState[selectMenu],
            ID: branchIdSelect,
            branch: value,
        }
        dispatch(datePickup.changeSelectAdminUser({ dataSelect, type: selectMenu }));
        // reset data
    }

    function loadDataGlist() {
        // dataBranchOnState.id
        const loadData = async () => {
            setLoading(true);
            const response = await branchApi.takeListOther(dataBranchOnState.glist.ID, token);
            setLoading(false);
            if (response.data.status === 1) {
                setData(response.data.data.glist)
            }
        }
        loadData();
    }

    function loadDataBranch() {
        // dataBranchOnState.id
        const loadData = async () => {
            setLoading(true);
            const response = await branchApi.getInform(dataBranchOnState.branchInfor.ID, token);
            setLoading(false);
            if (response.data) {
                const saveData = response.data[0]
                setDataBranchInfor(saveData)
            }
        }
        loadData();
    }

    function loadDataUser() {
        const loadData = async () => {
            setLoading(true);
            let key = ""
            if (dataBranchOnState.user.area === "Hai Phong / Da Nang") key = "HP_DN"
            else if (dataBranchOnState.user.area === "Cam/Man") key = "Ca_Ma"
            else key = dataBranchOnState.user.area;
            const response = await userApi.getUserWithBranch(key, token);
            setLoading(false);

            if (response.data.status === 1) {
                setDataUser(response.data.data);
                saveToast(response.data.msg);
            }
        }
        loadData();
    }

    function loadDataUserDatabase() {
        const loadData = async () => {
            setLoading(true);
            let key = ""
            if (dataBranchOnState.userDatabase.area === "Hai Phong / Da Nang") key = "HP_DN"
            else if (dataBranchOnState.userDatabase.area === "Cam/Man") key = "Ca_Ma"
            else key = dataBranchOnState.userDatabase.area;
            let response;
            let filterData;

            setLoading(false);

            if(key === "Select Area") {
                response = await userShareApi.getUserListLockDatabase("", token);
                filterData = response.data.data.data;
                console.log("response", response.data.data)
            } else {
                response = await userShareApi.getUserShareWithBranch(key, token);

                filterData = response.data.data.data.filter(item => {
                    if("other" in item) {
                        if("login" in item.other) {
                            const { listBlock, listActivated } = item.other.login;
                          //  console.log("listActivated.length", listActivated.length)
                            if(listBlock.length > 0 || listActivated.length > 0) return item
                        }
                    }
                });
            }

            if (response.data.status === 1) {
                setDataUserDatabase(filterData);
                saveToast(response.data.msg);
            }
        }
        loadData();
    }

    function handleLoadDataSurveyConfig() {
        const onLoadDataSurveyConfig = async () => {
            const dataSurveyConfig = await surveyApi.getSurveyConfigByType("tablet_check", token);
            setDataSurveyConfig(dataSurveyConfig.data);
        }
        onLoadDataSurveyConfig();

        //getSurveyConfigByType
    }

    function handleOpenActivePassSurvey(branchID, branchName, password) {
        var changeModal;
        changeModal = actModalChange("centered",
            "Confirm send mail", <ModalSupportOption
            title={`Do you want send password active to \n SUPERVISOR ${branchName} mail ?`}
            button1="Close"
            button2={"Send Mail"}
            featureButton1={() => closeModal()}
            featureButton2={() => onSendMailToSup()}
            typeButton2={"btn-primary"}
            type={null}
        />)

        const onSendMailToSup = async () => {
            // call API send mail pass active to sup mail
            const sendPass = await surveyApi.sendMailPasswordActiveTablet(branchID, password, token);
            if (!sendPass) saveToast("Error when send password");
            else saveToast(sendPass.data.msg);
            closeModal();
        }

        dispatch(changeModal);
        const openModal = actModalIsOk();
        dispatch(openModal);
    }

    function handleActionTabletActive(type, tablet, value) {
        var changeModal;
        changeModal = actModalChange("centered",
            "Confirm action", <ModalSupportOption
            title={`Do you want action ${type.toUpperCase()} this tablet ?`}
            button1="Close"
            button2={`Action ${type.toUpperCase()}`}
            featureButton1={() => closeModal()}
            featureButton2={() => callAPI()}
            typeButton2={type === "delete" ? "btn-danger" : "btn-primary"}
            type={null}
        />);

        dispatch(changeModal);
        const openModal = actModalIsOk();
        dispatch(openModal);

        const callAPI = async () => {
            const dataUpdate = { note: value };
            const activeTablet = await surveyApi.patchActionTabletActive(dataBranchOnState.survey.ID, type, tablet, dataUpdate, token);
            if (!activeTablet) saveToast("Error when update");
            else {
                if (activeTablet.data.status === 1) saveToast(activeTablet.data.msg);
                setSelectMenu("survey");
            }
            closeModal();
        }

    }

    function handleChangeUpdateSurveyInformation(dataChange) {
        var changeModal;
        changeModal = actModalChange("centered",
            "Confirm update data", <ModalSupportOption
            title={`Do you want update information  ?`}
            button1="Close"
            button2={`Confirm`}
            featureButton1={() => closeModal()}
            featureButton2={() => updateToServer()}
            typeButton2={"btn-primary"}
            type={null}
        />);

        dispatch(changeModal);
        const openModal = actModalIsOk();
        dispatch(openModal);

        const updateToServer = async () => {
            const result = await surveyApi.patchChangeSurveyConfigUpdate('update', dataChange, token);
            if (!result) saveToast("Error when update");
            else {
                if (result.data.status === 1) saveToast(result.data.msg);
                setSelectMenu("survey");
            }
            closeModal();
        }
    }

    function closeModal() {
        const closeModal = actResetModal();
        dispatch(closeModal);
    }

    const saveToast = (content) => {
        return toast.info(content, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    return (
        <React.Fragment>
            <h1>Manager User / Branch Information</h1>
            <div className={loading ? "" : "op-hidden"}>
                <p>loading...</p>
                <img src="https://res.cloudinary.com/dsm20cmen/image/upload/v1626411475/store/loading_short_daigrr.gif" alt="loading" />
            </div>

            <div className="d-flex manager-user">
                <div className="manager-user_menu">
                    <ul className="manager-user_menu-list" style={{ listStyle: 'none' }}>
                        <li className={"manager-user_menu-item " + (selectMenu === "userDatabase" ? "select" : "")}
                            onClick={() => setSelectMenu("userDatabase")}>User Database</li>
                        <li className={"manager-user_menu-item " + (selectMenu === "branchInfor" ? "select" : "")}
                            onClick={() => setSelectMenu("branchInfor")}>Branch</li>
                        <li className={"manager-user_menu-item " + (selectMenu === "user" ? "select" : "")}
                            onClick={() => setSelectMenu("user")}>User</li>
                        <li className={"manager-user_menu-item " + (selectMenu === "glist" ? "select" : "")}
                            onClick={() => setSelectMenu("glist")}>Glist</li>
                        <li className={"manager-user_menu-item " + (selectMenu === "survey" ? "select" : "")}
                            onClick={() => setSelectMenu("survey")}>Survey</li>
                    </ul>
                </div>

                <div style={{ width: '85%', }} className={"manager-user-content " + (selectMenu === "branchInfor" ? "" : "op-hidden")}>
                    <ManagerBranch
                        data={dataBranchInfor}
                        listArea={listArea}
                        listBranch={listBranch}
                        selectArea={dataBranchOnState.branchInfor.area}
                        selectBranch={dataBranchOnState.branchInfor.branch}
                        changeSelectArea={changeSelectAreaGlist}
                        changeSelectBranchID={changeSelectBranchIDGlist}
                        loadData={loadDataBranch}
                    />
                </div>

                <div style={{ width: '85%', }} className={"manager-user-content " + (selectMenu === "user" ? "" : "op-hidden")}>
                    <ManagerAllUser
                        data={dataUser}
                        target = "login"
                        listArea={listArea}
                        listBranch={listBranch}
                        selectArea={dataBranchOnState.user.area}
                        selectBranch={dataBranchOnState.user.branch}
                        changeSelectArea={changeSelectAreaGlist}
                        changeSelectBranchID={changeSelectBranchIDGlist}
                        onShowActionUserManager={onShowActionUserManager}
                        loadData={loadDataUser}
                        updateNewPassToServer={updateNewPassToServer}

                        onChangeActionGlist={onShowChangeAction}
                        onChangeNoteGlist={onChangeNoteGlist}
                        onUpdateNoteToServer={onUpdateNoteToServer}

                    />
                </div>

                <div style={{ width: '85%', }} className={"manager-user-content " + (selectMenu === "userDatabase" ? "" : "op-hidden")}>
                    <ManagerAllUser
                        data={dataUserDatabase}
                        target = {dataBranchOnState.userDatabase.area === "Select Area" ? "listBlockDatabase" : "loginDatabase"}
                        listArea={listArea}
                        listBranch={listBranch}
                        selectArea={dataBranchOnState.userDatabase.area}
                        selectBranch={dataBranchOnState.userDatabase.branch}
                        changeSelectArea={changeSelectAreaGlist}
                        changeSelectBranchID={changeSelectBranchIDGlist}
                        onShowActionUserManager={onShowActionUserManager}
                        loadData={loadDataUserDatabase}
                        updateNewPassToServer={updateNewPassToServer}
                        onShowChangeAction={onShowChangeAction}
                        onChangeActionGlist={onShowChangeAction}
                        onChangeNoteGlist={onChangeNoteGlist}
                        onUpdateNoteToServer={onUpdateNoteToServer}
                    />
                </div>

                <div style={{ width: '85%', }} className={"manager-user-content " + (selectMenu === "glist" ? "" : "op-hidden")}>
                    <ManagerUserGlist
                        type="glist"
                        data={data}
                        listArea={listArea}
                        listBranch={listBranch}
                        selectArea={dataBranchOnState.glist.area}
                        selectBranch={dataBranchOnState.glist.branch}

                        changeSelectArea={changeSelectAreaGlist}
                        changeSelectBranchID={changeSelectBranchIDGlist}
                        onChangeActionGlist={onShowChangeAction}
                        onChangeNoteGlist={onChangeNoteGlist}
                        onUpdateNoteToServer={onUpdateNoteToServer}

                        loadDataGlist={loadDataGlist}
                    />
                </div>

                <div style={{ width: '85%', }} className={"manager-user-content " + (selectMenu === "survey" ? "" : "op-hidden")}>
                    <ManagerSurvey
                        type="survey"
                        data={dataSurveyConfig}
                        token={token}
                        branchID={dataBranchOnState.survey.ID}
                        listArea={listArea}
                        listBranch={listBranch}
                        selectArea={dataBranchOnState.survey.area}
                        selectBranch={dataBranchOnState.survey.branch}
                        changeSelectArea={changeSelectAreaGlist}
                        changeSelectBranchID={changeSelectBranchIDGlist}
                        handleOpenActivePassSurvey={handleOpenActivePassSurvey}
                        handleActionTabletActive={handleActionTabletActive}
                        handleChangeUpdateSurveyInformation={handleChangeUpdateSurveyInformation}
                        onLoadData={handleLoadDataSurveyConfig}
                    />
                </div>

            </div>
        </React.Fragment >
    );
}

export default ManagerUser;
